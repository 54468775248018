import { Heading } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BrandPoliciesData } from "../../../../shared/apiTypes/apiTypes";

type Props = {
  policyName:
    | keyof NonNullable<BrandPoliciesData["luggagePolicy"]>
    | keyof NonNullable<BrandPoliciesData["passengerPolicy"]>;
  isAllowed: boolean;
};

const BrandPolicyHeadline: React.FC<Props> = ({ policyName, isAllowed }) => {
  const intl = useIntl();

  return (
    <Heading size={4} sectionHeader>
      <FormattedMessage
        id={`${policyName}.title`}
        values={{
          value: intl.formatMessage({ id: isAllowed ? "general.yes" : "general.no" }),
        }}
      />
    </Heading>
  );
};

export default BrandPolicyHeadline;
