import { Link } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { PartnerPoliciesData } from "../../shared/apiTypes/apiTypes";
import { partnerPolicyTitles } from "../../shared/helpers/accordionTitles/accordionTitles";
import customerServiceContactAccordionId from "../../shared/helpers/customerServiceContactAccordionId/customerServiceContactAccordionId";
import PolicyAccordion from "../policyAccordion/PolicyAccordion";
import CheckInRequirements from "./ui/checkInRequirements/CheckInRequirements";
import CustomerServiceInformationRow from "./ui/customerServiceInformationRow/CustomerServiceInformationRow";

type Props = {
  policies: PartnerPoliciesData;
};

const PartnerPolicies: React.FC<Props> = ({ policies }) => {
  const intl = useIntl();

  return (
    <>
      <PolicyAccordion
        title={intl.formatMessage({ id: partnerPolicyTitles[0].title })}
        InlineIcon={partnerPolicyTitles[0].icon}
        loading={false}
      >
        {policies.checkInTime.start && policies.checkInTime.end && (
          <CheckInRequirements
            start={policies.checkInTime.start}
            end={policies.checkInTime.end}
          />
        )}
      </PolicyAccordion>
      <PolicyAccordion
        title={intl.formatMessage({ id: partnerPolicyTitles[1].title })}
        InlineIcon={partnerPolicyTitles[1].icon}
        loading={false}
        idForAnchor={customerServiceContactAccordionId}
      >
        {policies.customerServiceContactV2.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <CustomerServiceInformationRow data={row} isMain={i === 0} key={i} />
        ))}
      </PolicyAccordion>
      <PolicyAccordion
        title={intl.formatMessage({ id: partnerPolicyTitles[2].title })}
        InlineIcon={partnerPolicyTitles[2].icon}
        loading={false}
      >
        <Link target="_blank" href={policies.termsAndConditionsUrl}>
          {policies.termsAndConditionsUrl}
        </Link>
      </PolicyAccordion>
    </>
  );
};

export default PartnerPolicies;
