import { ListWrapper, ListWrapperItem, Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";

const BrandListSkeleton: React.FC = () => {
  return (
    <ListWrapper aria-live="polite" aria-busy="true">
      {[...Array(8)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListWrapperItem key={i}>
          <Skeleton data-testid="skeleton" height="lg" width="xs" flushBottom />
        </ListWrapperItem>
      ))}
    </ListWrapper>
  );
};

export default BrandListSkeleton;
