import { UnaccompaniedMinorAllowanceRule } from "../../../../../shared/apiTypes/apiTypes";

export default (
  allowanceRule: UnaccompaniedMinorAllowanceRule
): "consent" | "noConsent" | "notAllowed" => {
  if (allowanceRule) {
    if (allowanceRule.parentalConsentRequired) {
      return "consent";
    }
    return "noConsent";
  }
  return "notAllowed";
};
