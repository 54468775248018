/* eslint-disable react/no-danger */
import { List } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UnaccompaniedMinorPolicy } from "../../../../shared/apiTypes/apiTypes";
import BrandPolicyHeadline from "../brandPolicyHeadline/BrandPolicyHeadline";
import getAllowanceSuffix from "./helper/getAllowanceSuffix";

type BrandPoliciesProps = {
  data: UnaccompaniedMinorPolicy;
};

const YoungTravellers: React.FC<BrandPoliciesProps> = ({ data }) => {
  const intl = useIntl();

  const { unaccompaniedMinorSpecification, allowed } = data;

  return (
    <>
      <BrandPolicyHeadline policyName="unaccompaniedMinorPolicy" isAllowed={allowed} />
      {allowed && unaccompaniedMinorSpecification !== null && (
        <List>
          {unaccompaniedMinorSpecification.minAgeYears && (
            <li data-testid="min-age">
              <FormattedMessage
                id="policies.unaccompaniedMinors.minAge.text"
                values={{
                  value: unaccompaniedMinorSpecification.minAgeYears,
                }}
              />
            </li>
          )}

          {unaccompaniedMinorSpecification.interconnectionTravel && (
            <li
              data-testid="interconnections"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: `policies.unaccompaniedMinors.interconnections.${getAllowanceSuffix(
                    unaccompaniedMinorSpecification.interconnectionTravel
                  )}`,
                }),
              }}
            />
          )}

          {unaccompaniedMinorSpecification.nightTravel && (
            <li
              data-testid="night-rides"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: `policies.unaccompaniedMinors.nightRides.${getAllowanceSuffix(
                    unaccompaniedMinorSpecification.nightTravel
                  )}`,
                }),
              }}
            />
          )}
          {unaccompaniedMinorSpecification.internationalTravel && (
            <li
              data-testid="international-travel"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: `policies.unaccompaniedMinors.international.${getAllowanceSuffix(
                    unaccompaniedMinorSpecification.internationalTravel
                  )}`,
                }),
              }}
            />
          )}
        </List>
      )}
    </>
  );
};

export default YoungTravellers;
