import {
  IconBike,
  IconCall,
  IconChild,
  IconComponent,
  IconDog,
  IconLink,
  IconLuggage,
  IconStroller,
  IconTime,
  IconWheelchair,
} from "@flixbus/honeycomb-icons-react";

export type AccordionTitleType = Array<{
  title: string;
  icon: IconComponent;
}>;

export const partnerPolicyTitles: AccordionTitleType = [
  {
    title: "checkInRequirements.headline",
    icon: IconTime,
  },
  {
    title: "customerServiceInformation.headline",
    icon: IconCall,
  },
  {
    title: "termsAndConditions.headline",
    icon: IconLink,
  },
];

export const brandPolicyTitles: AccordionTitleType = [
  {
    title: "policy.luggageAllowance.headline",
    icon: IconLuggage,
  },
  {
    title: "policy.specialLuggageAndBuggies.headline",
    icon: IconStroller,
  },
  {
    title: "policy.unaccompaniedMinors.headline",
    icon: IconChild,
  },
  {
    title: "policy.passengerWithDisabilityPolicy.headline",
    icon: IconWheelchair,
  },
  {
    title: "policy.bikesSurfboardsAndSkies.headline",
    icon: IconBike,
  },
  {
    title: "policy.pets.headline",
    icon: IconDog,
  },
];
