@import "variables";

.breadcrumbs {
  margin-bottom: $spacing-2;
  [class^="hcr-breadcrumbs__link"] {
    @include on-bp(md) {
      max-width: unset;
    }
  }
  & > li:last-child {
    > span {
      min-width: 40px;
    }
  }
}
