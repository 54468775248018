import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import { Accordion, Grid, GridCol, Spinner, Text } from "@flixbus/honeycomb-react";
import * as React from "react";
import PolicyNotSet from "../policyNotSet/PolicyNotSet";
import * as css from "./PolicyAccordion.scss";

type Props = {
  title: string;
  InlineIcon: IconComponent;
  loading: boolean;
  idForAnchor?: string;
};

const PolicyAccordion: React.FC<Props> = ({
  title,
  InlineIcon,
  loading,
  children,
  idForAnchor,
}) => {
  return (
    <>
      <Accordion
        extraClasses={css.accordion}
        title={
          <Grid>
            <GridCol inline>
              <Icon size={4} InlineIcon={InlineIcon} extraClasses={css.icon} />
            </GridCol>
            <GridCol>
              <Text extraClasses={css.title}>{title}</Text>
            </GridCol>
          </Grid>
        }
        id={idForAnchor}
      >
        {loading ? (
          <div
            aria-live="polite"
            aria-busy="true"
            className={css.spinner}
            data-testid="spinner"
          >
            <Spinner size="lg" />
          </div>
        ) : (
          <div aria-busy="false">
            {React.Children.toArray(children).length ? children : <PolicyNotSet />}
          </div>
        )}
      </Accordion>
    </>
  );
};

export default PolicyAccordion;
