import * as React from "react";
import { useIntl } from "react-intl";
import { AccordionTitleType } from "../../shared/helpers/accordionTitles/accordionTitles";
import PolicyAccordion from "../policyAccordion/PolicyAccordion";

type Props = {
  titles: AccordionTitleType;
};

const PoliciesSkeleton: React.FC<Props> = ({ titles }) => {
  const intl = useIntl();

  return (
    <>
      {titles.map((item) => (
        <PolicyAccordion
          key={item.title}
          title={intl.formatMessage({ id: item.title })}
          InlineIcon={item.icon}
          loading
        />
      ))}
    </>
  );
};

export default PoliciesSkeleton;
