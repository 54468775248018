import {
  phraseLocalesAmericas,
  phraseLocalesEurope,
  LanguageSwitcher as CommonLanguageSwitcher,
  useCurrentLocale,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";

const LanguageSwitcher: React.FC = () => {
  const [locale, setLocale] = useCurrentLocale();

  return (
    <CommonLanguageSwitcher
      currentLanguage={locale}
      onLanguageSelected={setLocale}
      languages={[
        {
          region: "Europe",
          locales: phraseLocalesEurope,
        },
        {
          region: "North & South America",
          locales: phraseLocalesAmericas,
        },
      ]}
    />
  );
};

export default LanguageSwitcher;
