import {
  Breadcrumbs as HCBreadcrumbs,
  BreadcrumbsItem,
  Skeleton,
} from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import LinkWithQuery from "../../shared/linkWithQuery/LinkWithQuery";
import * as css from "./Breadcrumbs.scss";

type Props = {
  title?: string;
};

const Breadcrumbs: React.FC<Props> = ({ title }) => {
  const intl = useIntl();

  return (
    <HCBreadcrumbs aria-label="breadcrumbs" extraClasses={css.breadcrumbs}>
      {/* @ts-ignore https://jira.flix.tech/browse/HIPP-396 */}
      <BreadcrumbsItem RouterLink={LinkWithQuery} to="/" href="/">
        {intl.formatMessage({ id: "home.title" })}
      </BreadcrumbsItem>
      {title ? (
        <BreadcrumbsItem>{title}</BreadcrumbsItem>
      ) : (
        <BreadcrumbsItem>
          {/* @ts-ignore https://jira.flix.tech/browse/HIPP-397 */}
          <Skeleton flushBottom />
        </BreadcrumbsItem>
      )}
    </HCBreadcrumbs>
  );
};

export default Breadcrumbs;
