import {
  defaultPhraseLocale,
  phraseLocales,
  useCurrentLocale,
  LOCALE_KEY,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

const MetaTags: React.FC = () => {
  const [locale] = useCurrentLocale();
  const intl = useIntl();

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale.bcp47Locale }}>
        <title>{intl.formatMessage({ id: "general.title" })}</title>
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${window.location.href.split("?")[0]}?${LOCALE_KEY}=${
            defaultPhraseLocale.locale
          }`}
        />
        {phraseLocales.map((lang) => (
          <link
            key={lang.bcp47Locale}
            rel="alternate"
            hrefLang={lang.bcp47Locale}
            href={`${window.location.href.split("?")[0]}?${LOCALE_KEY}=${lang.locale}`}
          />
        ))}
      </Helmet>
    </>
  );
};

export default MetaTags;
