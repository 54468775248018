import { LengthUnit, TimeUnit, WeightUnit } from "../../apiTypes/apiTypes";

type PluralizationSuffix = "few" | "many" | "one" | "other" | "two" | "zero";

export const translateTimeUnit = (
  unit: TimeUnit,
  suffix: PluralizationSuffix
): string => {
  if (unit === TimeUnit.DAYS) {
    return `general.day.${suffix}`;
  }
  if (unit === TimeUnit.HOURS) {
    return `general.hour.${suffix}`;
  }
  return `general.minute.${suffix}`;
};

export const translateLengthUnit = (unit: LengthUnit) => {
  if (unit === LengthUnit.CENTIMETERS) {
    return "cm";
  }
  return "inch";
};

export const translateWeightUnit = (unit: WeightUnit) => {
  if (unit === WeightUnit.KILOGRAMS) {
    return "kg";
  }
  return "lb";
};
