import { Footer, useCurrentLocale } from "@flixbus-phx/marketplace-common";
import * as React from "react";

const FooterWrapper: React.FC = () => {
  const [locale] = useCurrentLocale();

  return <Footer isSpa={false} locale={locale.locale} />;
};

export default FooterWrapper;
