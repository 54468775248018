import * as React from "react";

const highlightText = (text: string, filterValue?: string): JSX.Element | string => {
  if (filterValue) {
    const parts = text.split(new RegExp(`(${filterValue})`, "gi"));
    if (parts.length > 1) {
      return (
        <span>
          {parts.map((part, i) =>
            part.toLowerCase() === filterValue.toLowerCase() ? (
              // eslint-disable-next-line react/no-array-index-key
              <b key={i}>{part}</b>
            ) : (
              part
            )
          )}
        </span>
      );
    }
  }
  return text;
};

export default highlightText;
