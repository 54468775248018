import { List } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IncludedLuggagePolicy, PolicyUnits } from "../../../../shared/apiTypes/apiTypes";
import {
  translateLengthUnit,
  translateWeightUnit,
} from "../../../../shared/helpers/translateUnits/translateUnits";
import BrandPolicyHeadline from "../brandPolicyHeadline/BrandPolicyHeadline";

export type IncludedLuggageProps = {
  policyName: "handLuggagePolicy" | "checkInLuggagePolicy";
  data: IncludedLuggagePolicy;
  units: PolicyUnits;
};

const IncludedLuggage: React.FC<IncludedLuggageProps> = ({ policyName, data, units }) => {
  const { luggageSpecification, inTicketIncluded } = data;

  return (
    <>
      <BrandPolicyHeadline policyName={policyName} isAllowed={inTicketIncluded} />
      {inTicketIncluded && luggageSpecification !== null && (
        <List>
          {luggageSpecification.maxWeight && (
            <li data-testid="max-weight">
              <FormattedMessage
                id="policies.maxWeight.text"
                values={{
                  value: luggageSpecification.maxWeight.value,
                  unit: translateWeightUnit(units.weightUnit),
                }}
              />
            </li>
          )}
          {luggageSpecification.maxDimension && (
            <li data-testid="dimensions">
              <FormattedMessage
                id="policies.dimensions.text"
                values={{
                  value: luggageSpecification.maxDimension.value,
                  unit: translateLengthUnit(units.lengthUnit),
                }}
              />
            </li>
          )}
          {luggageSpecification.maxPieces && (
            <li data-testid="max-pieces">
              <FormattedMessage
                id="policies.maxPieces.text"
                values={{
                  value: luggageSpecification.maxPieces,
                }}
              />
            </li>
          )}
        </List>
      )}
    </>
  );
};

export default IncludedLuggage;
