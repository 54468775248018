import { FeeType } from "../../../../../shared/apiTypes/apiTypes";

export default (feeType: FeeType | null): string => {
  if (feeType) {
    if (feeType === FeeType.PRICE_PER_PIECE) {
      return "policies.fee.perPiece.text";
    }
    return "policies.fee.perUnit.text";
  }
  return "policies.fee.text";
};
