import { BrandsList } from "../../../../shared/apiTypes/apiTypes";

const filterBrands = (brands: BrandsList, filterValue?: string): BrandsList => {
  if (filterValue) {
    const cleanFilterValue = filterValue?.trim();

    return brands.filter(({ name }) =>
      name.toLowerCase().includes(cleanFilterValue.toLowerCase())
    );
  }
  return brands.sort((a, b) => a.name.localeCompare(b.name));
};

export default filterBrands;
