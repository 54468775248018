import { List } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PartnerPoliciesData } from "../../../../shared/apiTypes/apiTypes";
import { translateTimeUnit } from "../../../../shared/helpers/translateUnits/translateUnits";

export type CheckInRequirementsProps = {
  start: NonNullable<PartnerPoliciesData["checkInTime"]["start"]>;
  end: NonNullable<PartnerPoliciesData["checkInTime"]["end"]>;
};

const CheckInRequirements: React.FC<CheckInRequirementsProps> = ({ start, end }) => {
  const intl = useIntl();

  return (
    <>
      <List>
        <li>
          <FormattedMessage
            id="checkInRequirements.start"
            values={{
              value: start.value,
              unit: intl.formatMessage({
                id: translateTimeUnit(start.unit, intl.formatPlural(start.value)),
              }),
            }}
          />
        </li>
        <li>
          <FormattedMessage
            id="checkInRequirements.close"
            values={{
              value: end.value,
              unit: intl.formatMessage({
                id: translateTimeUnit(end.unit, intl.formatPlural(end.value)),
              }),
            }}
          />
        </li>
      </List>
    </>
  );
};

export default CheckInRequirements;
