 /* Overwriting HC Header to make it work on mobile screens */

 .hcr-header-nav__nav {
   display: none !important;
 }

 div[class^="hcr-page-container-"] {
   padding-top: calc(var(--hcr-spacing-10) + var(--hcr-spacing-4)) !important;
   padding-bottom: var(--hcr-spacing-4);
 }

 div[class^="hcr-header-widgets-"] {
   display: block !important;
 }

 a[class^="hcr-header-brand__link"] {
   text-align: left !important;
 }

 div[class^="hcr-header-brand-"] {
   justify-content: flex-start !important;
 }