import { Heading, Text } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import BrandList from "../../components/brandList/BrandList";
import BrandSearch from "../../components/brandSearch/BrandSearch";
import * as css from "./Home.scss";

const Home: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState<string>();

  return (
    <>
      <Heading size={1} extraClasses={css.heading}>
        <FormattedMessage id="home.title" />
      </Heading>
      <Text>
        <FormattedMessage id="home.secondTitle" />
      </Text>
      <BrandSearch onChange={setSearchValue} />
      <BrandList filterValue={searchValue} />
    </>
  );
};

export default Home;
