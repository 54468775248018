import { Icon, IconMagnifier } from "@flixbus/honeycomb-icons-react";
import { Input } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import * as css from "./BrandSearch.scss";

type Props = {
  onChange: (value: string) => void;
};

const BrandSearch: React.FC<Props> = ({ onChange }) => {
  const intl = useIntl();

  return (
    <div className={css.container}>
      <Input
        id="search-brand"
        label={intl.formatMessage({ id: "carrierSearch.label" })}
        placeholder={intl.formatMessage({ id: "carrierSearch.placeholder" })}
        iconLeft={<Icon InlineIcon={IconMagnifier} />}
        onChange={(e) => onChange(e.currentTarget.value)}
        type="search"
      />
    </div>
  );
};

export default BrandSearch;
