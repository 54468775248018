import { Heading, Skeleton, Text } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./BrandPageHeader.scss";

type Props = {
  brandName?: string;
  legalCompanyName?: string;
};

const BrandPageHeader: React.FC<Props> = ({ brandName, legalCompanyName }) => {
  return (
    <>
      <Heading size={1} extraClasses={css.heading}>
        {brandName || <Skeleton flushBottom extraClasses={css.headingSkeleton} />}
      </Heading>
      <Text extraClasses={css.subline} data-testid="subline">
        {legalCompanyName ? (
          <>
            {legalCompanyName} (<FormattedMessage id="general.legalCompanyName" />)
          </>
        ) : (
          <Skeleton extraClasses={css.sublineSkeleton} />
        )}
      </Text>
      <Text extraClasses={css.text}>
        <FormattedMessage id="brandPage.text" />
      </Text>
    </>
  );
};

export default BrandPageHeader;
