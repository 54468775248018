import { MainWrapper, PageContainer, ThemeWrapper } from "@flixbus/honeycomb-react";
import {
  ErrorBoundary,
  Navigation,
  TranslationProvider,
  legacyTranslate,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import "normalize.css/normalize.css";
import FooterWrapper from "./components/footerWrapper/FooterWrapper";
import LanguageSwitcher from "./components/languageSwitcher/LanguageSwitcher";
import MetaTags from "./components/metaTags/MetaTags";
import "./shared/styles/header-overwrites.css";
import Router from "./routes/router/Router";
import LinkWithQuery from "./shared/linkWithQuery/LinkWithQuery";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeWrapper>
        <TranslationProvider usesCookies={false} isUsedInternal={false}>
          <ErrorBoundary translate={legacyTranslate}>
            <MetaTags />
            <MainWrapper full>
              <Navigation
                widget={<LanguageSwitcher />}
                isUsedInternal={false}
                navigationItems={[]}
                RouterLink={LinkWithQuery}
                translate={legacyTranslate}
              />
              <PageContainer hasFixedHeader>
                <Router />
                <FooterWrapper />
              </PageContainer>
            </MainWrapper>
          </ErrorBoundary>
        </TranslationProvider>
      </ThemeWrapper>
    </BrowserRouter>
  );
};

export default App;
